import * as React from 'react'
import Layout from '../components/layout'

import { StaticImage } from 'gatsby-plugin-image'


// styles
import "../styles/global.css";
import "../styles/playground.css";
import "@fontsource/sora/300.css";
import "@fontsource/sora/500.css";
import "@fontsource/sora/400.css";
import "@fontsource/sora/600.css";
import "@fontsource/sora";
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import "@fontsource/caveat";

//import { useState } from 'react';

const PlaygroundPage = () => {

  return (
    <Layout pageTitle="playground">

    <div className="contentPlayground" >
      
      <h2>Playground</h2>
      
      <p>Hi there! This page is in construction, come back soon</p>

      <StaticImage
        alt="Mayte avatar"
        src="../images/homePic.png"
        placeholder="blurred"
        layout="constrained"
        width={150}
        height={150}
      />
      
      
    </div>

      


    </Layout>
  )
}

export default PlaygroundPage